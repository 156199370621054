<template>
  <b-overlay :show="loading">
    <div class="home-container">
      <pagination :implementSearch="false" :meta="classifiesMeta" @refresh="onRefresh" @change-page="onChangePage">
        <b-button slot="action" :to="{name: `classify.${classify}.store`}" class="btn btn-label-success btn-sm border-0">
          {{ $t("COMMON.CREATE") }}
        </b-button>
      </pagination>
      <b-row>
        <b-col sm="6" md="3" v-for="item in classifies" :key="item.id" class="d-flex">
          <portlet foot-class="mt-auto">
            <template v-slot:cleanBody>
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item" v-if="item.hasOwnProperty(lang)">
                      <div class="kt-widget__contact cursor-default">
                        <span class="kt-widget__label">{{ $t("PAGES.HOSPITAL_DEPARTMENT.HOME.LABEL.title") }}</span>
                        <span class="kt-widget__data">{{ item[lang].title }}</span>
                      </div>
                      <div class="kt-widget__contact cursor-default" v-if="item[lang].describe">
                        <span class="kt-widget__data">{{ item[lang].describe }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:foot>
              <b-button-group>
                <b-button block variant="primary" :pressed="false" :to="{name: `classify.${classify}.edit`, params: {id: item.id}}">{{ $t("COMMON.FORM.BUTTONS.EDIT") }}</b-button>
                <b-button variant="danger" :pressed="false" @click="onChangeSwitch(item.id, 'is_show', item.is_show)"><span class="fa " :class="[item.is_show ? 'fa-eye' : 'fa-eye-slash']"></span></b-button>
              </b-button-group>
            </template>
          </portlet>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import Portlet from "@v@/partials/content/Portlet";
  import pagination from "@v@/components/paginate";
  import classifyMixin from "@m@/classify.mixin";
  import {mapGetters} from 'vuex';
  import {SET_CLASSIFIES, SET_CLASSIFY_ERROR} from "../../../../store/modules/classify.module";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  export default {
    name: "index",
    components: { Error, Portlet, pagination },
    mixins: [classifyMixin, msgBoxConfirmMixin, commonMethodMixin],
    props: {
      classify: {
        type: String,
        default: ()=> ''
      }
    },
    created() {
      // this.autoLoadHomeData(1);
    },
    mounted() {
      this.setBreadCrumbs();
    },
    watch: {
      'classify': {
        handler(v){
          this.setBreadCrumbs();
          this.autoLoadHomeData(1);
        },
        immediate: true
      }
    },
    computed: {
      ...mapGetters([
         'classifies', 'classifiesMeta', 'classifiesPage'
      ]),
      lang(){
        return this.$i18n.locale || 'ZH-UG';
      },
    },
    data(){
      return {
        loading: false,
        page: 1
      }
    },
    methods: {
      setBreadCrumbs(){
        BreadCrumbs.setBreadCrumb([]);
        BreadCrumbs.addBreadCrumb({ title: this.$t("PAGES.CLASSIFY.HOME.BREAD_CRUMBS_TITLES.first") });
        switch (this.classify) {
          case "house":
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.HOME.BREAD_CRUMBS_TITLES.house"), route: {name: 'classify.house.home'}});
            break;
          case "car":
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.HOME.BREAD_CRUMBS_TITLES.car"), route: {name: 'classify.car.home'}});
            break;
          case "job":
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.HOME.BREAD_CRUMBS_TITLES.job"), route: {name: 'classify.job.home'}});
            break;
          case "source_income":
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.HOME.BREAD_CRUMBS_TITLES.source_income"), route: {name: 'classify.source_income.home'}});
            break;
        }
      },
      autoLoadHomeData(rootPage = null){
        this.loading = true;
        let {page: classifyPage, belongs} = this.classifiesPage;
        let { classify } = this;
        if( belongs === classify && ! rootPage ){
          this.page = classifyPage
        }else if (rootPage) {
          this.page = rootPage
        }
        this.getClassifies(this.page, this.classify)
          .catch(err=>{
            console.info('getClassifies.err: ', err);
          }).finally(()=>{
            this.loading = false;
          })
      },
      onChangePage(e){
        this.autoLoadHomeData(e.page);
      },
      onRefresh(){
        this.autoLoadHomeData(1);
      },
      onChangeSwitch(id, column, isShow){
        this.msgBoxConfirm({
          message: isShow ? this.$t("PAGES.CLASSIFY.HOME.CONFIRM_TIPS.CLOSE"): this.$t("PAGES.CLASSIFY.HOME.CONFIRM_TIPS.OPEN"),
          confirmCallback: confirm=>{
            if ( confirm ){
              this.switchColumn({
                resource: 'classifies',
                id, column, mutationType: SET_CLASSIFY_ERROR,
                begin: ()=>{ this.loading = true },
                after: ()=>{ this.loading = false },
              }).then((res)=>{
                let { is_show } = res.data;
                let { classifies, classifiesMeta } = this;
                let tmp = classifies.map(item=>{
                  if( item.id === id ){
                    item.is_show = is_show;
                  }
                  return item;
                });
                this.$store.commit(SET_CLASSIFIES, { data: tmp, meta: classifiesMeta});
              })
            }
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-container {
    .btn-group, .btn-group-vertical {
      display: flex !important;
    }
  }

</style>
