import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import { GET_CLASSIFIES, GET_CLASSIFY, STORE_CLASSIFY, UPDATE_CLASSIFY } from "../store/modules/classify.module";
export  default {
  methods: {
    storeClassify(formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(STORE_CLASSIFY, formData), actions)
    },
    getClassify(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_CLASSIFY, id), actions)
    },
    updateClassify(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(UPDATE_CLASSIFY, { id, formData }), actions)
    },
    getClassifies(page = 1, belongs = 'house', actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_CLASSIFIES, { page, belongs }), actions)
    },
  }
}
