<template>
  <b-overlay :show="loading">
    <classify-form :classify="classify" ref="form" @loading="onLoading" @loaded="onLoaded" />
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import Portlet from "@v@/partials/content/Portlet";
  import ClassifyForm from "@v@/components/forms/ClassifyForm/index";
  export default {
    name: "index",
    components: { Error, Portlet, ClassifyForm },
    props: {
      classify: {
        type: String,
        default: ()=> 'house'
      }
    },
    created() {},
    mounted() {
      this.setBreadCrumbs();
    },
    watch: {
      'classify': {
        handler(v){
          this.setBreadCrumbs();
        },
        immediate: true
      }
    },
    computed: {
      portletTitle(){
        switch (this.classify) {
          case "house":
            return this.$t("PAGES.CLASSIFY.STORE.PORTLET_TITLES.house");
            break;
          case "car":
            return this.$t("PAGES.CLASSIFY.STORE.PORTLET_TITLES.car");
            break;
          case "job":
            return this.$t("PAGES.CLASSIFY.STORE.PORTLET_TITLES.job");
            break;
          case "source_income":
            return this.$t("PAGES.CLASSIFY.STORE.PORTLET_TITLES.source_income");
            break;
        }
      },
    },
    data(){
      return {
        loading: false
      }
    },
    methods: {
      setBreadCrumbs(){
        BreadCrumbs.setBreadCrumb([]);
        BreadCrumbs.addBreadCrumb({ title: this.$t("PAGES.CLASSIFY.STORE.BREAD_CRUMBS_TITLES.first") });
        switch (this.classify) {
          case "house":
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.STORE.BREAD_CRUMBS_TITLES.house_first"), route: {name: 'classify.house.home'}});
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.STORE.BREAD_CRUMBS_TITLES.house")});
            break;
          case "car":
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.STORE.BREAD_CRUMBS_TITLES.car_first"), route: {name: 'classify.car.home'}});
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.STORE.BREAD_CRUMBS_TITLES.car")});
            break;
          case "job":
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.STORE.BREAD_CRUMBS_TITLES.job_first"), route: {name: 'classify.job.home'}});
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.STORE.BREAD_CRUMBS_TITLES.job")});
            break;
          case "source_income":
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.STORE.BREAD_CRUMBS_TITLES.source_income_first"), route: {name: 'classify.source_income.home'}});
            BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.CLASSIFY.STORE.BREAD_CRUMBS_TITLES.source_income")});
            break;
        }
      },
      onSubmit(){
        const form = this.$refs['form'];
        form.onSubmit();
      },
      onLoading(){
        this.loading = true;
      },
      onLoaded(e){
        this.loading = false;
      },
    }
  }
</script>

<style lang="scss">
  .footer-actions {
    gap: 10px;
  }
</style>
